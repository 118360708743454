import React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import ReactDOMServer from 'react-dom/server'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import styled from 'styled-components'
import { colors, typography, VARIABLE_CONSTANTS, VW } from '../../styles'
import SingleAsset from './SingleAsset'
import DoubleAsset from './DoubleAsset'
import he from 'he'
import { handleAnchorTags } from '../../utils';

let moduleIndex = -1

const ArticleBody = ({
  content: {
    json: content
  }
}) => {

  const entryRenderer = ({data: { target: {
    fields: data
  }}}) => {

    const type = data.type.en
    moduleIndex++

    const addedProps = {
      key: `Article-${moduleIndex}`,
      data,
      moduleIndex: moduleIndex
    }
    
    switch (type) {
      case 'Center':
      case 'Full Width':
        return <SingleAsset {...addedProps} />

      case 'Small Big':
      case 'Big Small':
        return <DoubleAsset {...addedProps} />

      default:
        console.error('Module type not found')
    }
  }

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: entryRenderer
    }
  }

  const nodes = documentToReactComponents(content, options) || (<></>)

  let html = ReactDOMServer.renderToString(nodes)

  html = html.replace(/<p data-reactroot=""><b>/g, '<p class="bold"><b>')
  html = html.replace(/<p data-reactroot=""><i>/g, '<p class="italic"><i>')
  
  html = html.split('<p data-reactroot=""></p>')

  if(html[html.length - 1] === '') {
    html.pop()
  }

  html = html.join('<p data-reactroot=""></p>')

  html = handleAnchorTags(html)
  
  html = he.decode(html);

  return (
    <ArticleBody_wrapper>
      <div dangerouslySetInnerHTML={{__html: html}} />
    </ArticleBody_wrapper>
  )
}

const ArticleBody_wrapper = styled.div`
  padding-bottom: 80px;

  & > div > * {
    margin: auto;
  }

  & > div > div {
    margin-top: 70px;

    @media (min-width: ${VW.desktop}) {
      margin-top: 120px;
    }
  }

  & > div > p {
    ${typography.body.regular}
    max-width: ${VARIABLE_CONSTANTS.articleWidth};
    padding-left: 20px;
    padding-right: 20px;
  }

  blockquote,
  i {
    display: block;
    color: ${colors.red};
    text-align: center;
    width: 100%;
  }

  blockquote {
    ${typography.body.big}
    max-width: 950px;
    line-height: 30px;
    margin-top: 60px;
    padding: 0 20px 20px 20px;
    box-sizing: border-box;
    
    p {
      margin: 0;
      background-image: linear-gradient(90deg,currentColor,currentColor);
      background-size: 100% 2px;
      background-repeat: no-repeat;
      background-position: 0 96%;
      display: inline;
    }

    @media (min-width: ${VW.desktop}) {
      ${typography.headlines.articleAlternate}
      box-sizing: initial;
      margin-top: 120px;
      line-height: 60px;
    }
  }

  a {
    color: ${colors.red};
  }

  .bold {
    margin-top: 60px;
    font-weight: bold;
  }

  p + p:not(.bold) {
    margin-top: 25px;

    @media (min-width: ${VW.desktop}) {
      margin-top: 30px;  
    }
  }

  blockquote + p,
  div + p.bold,
  .italic + p,
  .italic + p.bold,
  div + p,
  div + p.bold {
    margin-top: 70px !important;

    @media (min-width: ${VW.desktop}) {
      margin-top: 100px !important;
    }
  }

  i {
    ${typography.body.tiny}
    padding-bottom: 20px;

    @media (min-width: ${VW.desktop}) {
      ${typography.body.small}
    }
  }

  .italic {
    margin-top: 10px !important;

    @media (min-width: ${VW.desktop}) {
      margin-top: 35px !important;
    }
  }

  .bold + p {
    margin-top: 20px !important;
  }

  @media (min-width: ${VW.desktop}) {
    padding-bottom: 120px;
  }
`

export default ArticleBody