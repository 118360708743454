import React from 'react'
import styled from 'styled-components'
import { colors, font, sectionGrid, typography, VW } from '../../styles'
import RichText from '../RichText'

const DoubleAsset = ({
  data: {
    images: {
      en: images
    },
    firstImageCaption: {
      en: firstImageCaption
    } = { en: null },
    secondImageCaption: {
      en: secondImageCaption
    } = { en: null },
    type: {
      en: type
    }
  },
  moduleIndex
}) => {

  return (
    <DoubleAsset_wrapper isSmallBig={type === 'Small Big'}>
      {images.map(({
        fields: {
          file: { en: {
            url: image
          }},
          description
        }
      }, i) => {
        return (
          <Image_wrapper key={`Double-Asset${moduleIndex}-${i}`}>
            <Image
              src={image}
              alt={description} />
            {(i === 0 && firstImageCaption) && (
              <Caption>
                <RichText json={firstImageCaption} />
              </Caption>
            )}
            {(i === 1 && secondImageCaption) && (
              <Caption>
                <RichText json={secondImageCaption} />
              </Caption>
            )}
          </Image_wrapper>
        )
      })}
    </DoubleAsset_wrapper>
  )
}

const DoubleAsset_wrapper = styled.div`
  ${sectionGrid}
  align-items: center;
  padding: 0 20px;
  column-gap: 20px;

  & > div {
    &:first-child {
      grid-column-start: 1;
      grid-column-end: ${props => props.isSmallBig ? '4' : '5'};
    }

    &:last-child {
      grid-column-start: ${props => props.isSmallBig ? '2' : '3'};
      margin-top: 70px;
    }
  }

  @media (max-width: calc(${VW.desktop} - 1px)) {
    grid-template-columns: repeat(5, 1fr);

    & > div:last-child {
      grid-column-end: 6;
    }
  }

  @media (min-width: ${VW.desktop}) {
    & > div {
      &:first-child {
        grid-column-end: ${props => props.isSmallBig ? '6' : '7'};
      }
  
      &:last-child {
        grid-column-start: ${props => props.isSmallBig ? '7' : '8'};
        grid-column-end: 13;
        margin-top: initial;
      }
    }
  }
`

const Image_wrapper = styled.div`
  
`

const Image = styled.img``

const Caption = styled.div`
  ${typography.body.tiny}
  font-family: ${font.typewriter};
  color: ${colors.darkGrey};
  text-align: center;

  p {
    margin: 15px 0 0;
  }

  @media (max-width: ${VW.desktop}) {
    p {
      margin: 10px 0 0;
    }
  }
`

export default DoubleAsset