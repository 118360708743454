import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import styled from 'styled-components'
import ArticleIntro from '../components/article/ArticleIntro'
import ArticleBody from '../components/article/ArticleBody'

const Article = ({
  data: {
    contentfulArticlePage: {
      slug,
      seoTitle,
      seoDescription,
      seoImage,
      introImage,
      introTitle,
      content
    }
  }
}) => {

  return (
    <Article_wrapper>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={seoImage}
        slug={slug} />

        <Article_container>
          <ArticleIntro
            image={introImage}
            title={introTitle} />
          <ArticleBody content={content} />
        </Article_container>
    </Article_wrapper>
  )
}

const Article_wrapper = styled.div``

const Article_container = styled.div`
  img {
    display: block;
    width: 100%;
  }
`

export const ArticleQuery = graphql`
  query Article($id: String!) {
    contentfulArticlePage(id: { eq: $id }) {
      slug
      seoTitle
      seoDescription
      seoImage {
        fluid {
          src
        }
      }
      introImage {
        fluid(maxWidth: 1600, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      },
      introTitle {
        json
      },
      content {
        json
      }
    }
  }
`

export default Article