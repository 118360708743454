import React from 'react'
import styled from 'styled-components'
import RichText from '../RichText'
import { typography, VARIABLE_CONSTANTS, VW } from '../../styles'
import Image from '../shared/Image'

const ArticleIntro = ({ 
  image: {
    fluid: image,
    description
  },
  title: {
    json: title
  }
}) => {
  return (
    <ArticleIntro_wrapper>
      <Image_wrapper>
        <Image
          fluid={image}
          alt={description} />
      </Image_wrapper>
      <Title>
        <RichText
          json={title}
          isHeader />
      </Title>
    </ArticleIntro_wrapper>
  )
}

const ArticleIntro_wrapper = styled.div``

const Image_wrapper = styled.div`
  border-bottom: 2px solid #000;
  max-height: 650px;
  overflow: hidden;
`

const Title= styled.div`
  ${typography.headlines.regular}
  max-width: ${VARIABLE_CONSTANTS.articleWidth};
  margin: 50px auto 40px;
  text-align: center;

  @media (min-width: ${VW.desktop}) {
    ${typography.headlines.article}
    margin: 100px auto 60px;
  }
`

export default ArticleIntro