import React from 'react'
import styled from 'styled-components'
import { colors, font, sectionGrid, typography, VW } from '../../styles'
import RichText from '../RichText'

const SingleAsset = ({ data: {
  images: { en: [{
    fields: {
      file: { en: {
        url: image
      }},
      description
    }

  }]},
  firstImageCaption: {
    en: firstImageCaption
  } = { en: null },
  type: {
    en: type
  }
}}) => {
  return (
    <SingleAsset_wrapper type={type}>
      <Image
        src={image}
        alt={description} />
      {firstImageCaption && (
        <Caption>
          <RichText json={firstImageCaption} />
        </Caption>
      )}
    </SingleAsset_wrapper>
  )
}

const SingleAsset_wrapper = styled.div`
  ${props => props.type === 'Center' && ({
    ...sectionGrid,
    padding: '0 20px;'
  })}
`

const Caption = styled.div`
  ${typography.body.tiny}
  font-family: ${font.typewriter};
  color: ${colors.darkGrey};
  text-align: center;

  p {
    margin: 15px 0 0;
  }

  @media (max-width: ${VW.desktop}) {
    p {
      margin: 10px 0 0;
    }
  }
`

const Image = styled.img`
  
`

export default SingleAsset